import React from 'react';

// import loseFace from '@edu-game/img/games/post-challege/post-challenge-lose-face.svg';

class App extends React.Component {
  render() {
    return (
      <div class="page--404">
        <div class="island">
          <div class="wrap--smaller">
            <img
              alt="sad face"
              src="/assets/img/games/post-challenge/post-challenge-lose-face.svg"
            />

            <h1 class="fs--mega">Oh no! One of those 404 errors!</h1>

            <p>Either the page you're looking for isn't here anymore, or it never existed.</p>

            <p>You can get back to safety here:</p>

            <p>
              <a class="btn--blue-alpha-dkr" href="/">
                Parent Login
              </a>{' '}
              <a class="btn--blue-alpha-dkr" href="/play">
                Player Login
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
