import 'proxy-polyfill';
import 'babel-polyfill';

import '../../css/style-game.scss';

import React from 'react';
import {render} from 'react-dom';

import App from './App';

const element = document.querySelector('.js-mount-point');

render(<App />, element);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
